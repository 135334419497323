@import "../utilities";

.slick-slider {
  max-width: min(1427px, 100vw);
  // width: 100%;
  // overflow: hidden;
  margin: auto;
}
.slick-slider,
.slick-list,
.slick-track,
.slick-slide {
  position: static;
  height: 100%;
}

.slick-list {
  margin: 0 -1rem;
}
.slick-slide {
  > div {
    margin: 0 1rem;
  }
}

.slide-holder {
}

// .slick-arrow {
//   width: 3rem;
//   z-index: 10;
//   display: flex;
//   align-items: center;
//   padding: 1.4rem 0.7rem;
//   border-radius: 3px;
//   background-color: $color-white-3;
//   transition: 0.2s ease-in-out;
//   top: unset;
//   bottom: 0rem;

//   svg {
//     font-size: 1.7rem;
//     color: $color-primary;
//   }

//   &::before {
//     display: none;
//   }

//   &.slick-next {
//     left: unset;
//     right: calc(50% - 3.4rem);
//   }
//   &.slick-prev {
//     right: unset;
//     left: calc(50% - 3.4rem);
//   }

//   &:lang(ar) {
//     &.slick-next {
//       right: 0;
//       left: unset;
//     }
//     &.slick-prev {
//       left: 0;
//       right: unset;
//     }
//   }

//   &:hover {
//     background-color: $color-primary;
//     svg {
//       color: $color-white-1;
//     }
//   }
// }

// .slider_holder {
//   padding: 14rem 0 18rem;
//   .slider-wrabber {
//     min-height: 26rem;
//     padding-bottom: 6rem;

//     .slide_content_holder {
//       padding: 0 1rem;
//       .image_holder {
//         padding: 0 0rem;
//         border-radius: 1rem;
//         overflow: hidden;
//         min-height: 19rem;
//         display: flex;
//         flex: 1;
//       }
//     }
//   }
// }

.banner_hero {
  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    gap: 3.7rem;
    position: absolute !important;
    bottom: 4.6rem;
    bottom: 6%;
    max-width: 90%;

    li {
      width: 7.9rem;
      height: 0.8rem;
      padding: 0;
      margin: 0;
      border-radius: 4px;
      background-color: $color-white-1;
      transition: 0.3s linear;

      button {
        width: 100%;
        height: 100%;
        padding: 0;
        background-color: transparent;
        &::before {
          display: none;
        }
      }
    }
    .slick-active {
      width: 11.5rem;

      background-color: $color-primary;
      button {
      }
    }
  }
}

.home_page {
  .slider_design_one {
    position: relative;
    // .slick-list {
    //   margin: 0 -3rem;
    // }
    // .slick-slide > div {
    //   padding: 0 3rem;
    // }
  }

  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: absolute !important;
    bottom: 4.6rem;
    bottom: 6%;
    max-width: 90%;

    li {
      width: 1.4rem;
      height: 1.4rem;
      padding: 0;
      margin: 0;
      border-radius: 10rem;
      background-color: rgba(95, 31, 100, 0.42);
      transition: 0.3s linear;

      button {
        width: 100%;
        height: 100%;
        padding: 0;
        background-color: transparent;
        &::before {
          display: none;
        }
      }
    }
    .slick-active {
      width: 4.9rem;

      background-color: $color-primary;
      button {
      }
    }
  }
}
