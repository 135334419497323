@import "../../styles/utilities";

.search_hero {
  & > span {
    height: 100%;
    & > span {
      height: 100%;
      padding-left: 3rem;
      border: 0 !important;
      border-radius: 26px 0 0 26px !important;
      box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      &:lang(ar) {
        border-radius: 0 26px 26px 0 !important;
      }
    }
  }
  input {
    // background-color: #fff;
  }
  button {
    height: 100% !important;
    padding: 2rem 5rem;
    display: flex;
    align-items: center;
    border-radius: 0 100px 100px 0 !important;
    overflow: hidden !important;

    &:lang(ar) {
      border-radius: 100px 0 0 100px !important;
    }

    @include mq("md") {
      padding: 2rem 3.5rem;
    }

    span {
      font-size: 2.5rem;
      font-weight: 500;
      color: #fff;
      text-transform: capitalize;
    }
  }

  svg {
    font-size: 1.8rem;
    color: $color-secondary;
    margin-right: 1rem;
  }
}
