.header_page_box {
  background-color: #c58e00;
  border-radius: 1.5rem;
  padding: 2.4rem;
  margin: 3rem 0;

  h2 {
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 3.6rem;
    text-transform: capitalize;

    @include mq("tablet") {
      font-size: 2.8rem;
    }
  }
}

.media_page_content {
  .tabs_holder {
    .ant-tabs {
      .ant-tabs-nav-wrap {
        // justify-content: flex-start;
        align-items: center;
        padding: 2rem 0;

        .ant-tabs-nav-list {
          gap: 1.4rem 2.4rem;
          justify-content: flex-start;
          justify-content: center;
          flex-wrap: wrap;

          @include mq("ipad") {
            gap: 1.4rem 2rem;
          }
        }
        .ant-tabs-tab {
          min-width: 18.2rem;
          min-height: 7.5rem;
          background-color: $color-white-2;
          border-radius: 1rem;
          border: 1px solid #f0f0f0;
          padding: 0.8rem;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          //   background: #e5e5e5;

          //   .ant-tabs-tab-btn {
          //     font-size: 1.4rem;
          //     font-weight: 500;
          //     text-transform: capitalize;
          //     color: $color-primary;
          //     background-color: #c58e00;
          //   }

          &.ant-tabs-tab-active {
            background-color: $color-primary;
            // .ant-tabs-tab-btn {
            //   color: $color-white-1;
            // }
          }
        }

        @include mq("ipad") {
          justify-content: center;
        }
      }
      //
      .ant-tabs-ink-bar-animated,
      .ant-tabs-nav::before {
        display: none !important;
      }
    }
  }
}

.modal_card {
  padding: 4rem;
  border-radius: 26px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  width: fit-content;

  .modal_card_inner {
    min-width: min(63rem, 83vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3.2rem;
  }
}

// Typography
.btn {
  border: none;
  outline: none;
  background-color: unset;
  cursor: pointer;
  padding: 0;
  font-size: 1.4rem;
  text-transform: capitalize;
}
.btn-primary {
  padding: 1rem 4rem;
  height: 9.5rem;
  border-radius: 48.8px;
  background-image: linear-gradient(100deg, #8c4191 -39%, #612166 51%);
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  color: $color-white-1;
  text-transform: capitalize;

  &.fw {
    display: block;
    width: 100%;
  }
}

.box-btns {
  padding-top: 6rem;
  display: flex;
  gap: 1.4rem;
  .color-secondary {
    background-image: none !important;
    background-color: $color-black-3 !important;
  }

  @include mq("sm") {
    flex-direction: column;
  }
}

.color-primary {
  color: $color-primary;
}

.bg-primary {
  background: rgb(97, 33, 102);
  background-image: linear-gradient(
    225deg,
    rgb(97, 33, 102) 40%,
    #8c419173 100%
  );
}

.bg-blur {
  position: relative;
  * {
    z-index: 2;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $color-white-1;
    filter: blur(5px);
    z-index: 1;
  }
}

.heading-primary {
  font-size: 6rem;
  font-weight: 600;
  line-height: 1.63;
  letter-spacing: 0.48px;
  color: $color-secondary;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  &:lang(ar) {
    flex-direction: row-reverse;
  }
  &.left {
    text-align: left;
    justify-content: flex-start;
    &:lang(ar) {
      justify-content: flex-end;
    }
  }
  .color {
    color: $color-primary;
    // padding-left: 5px;
  }

  &.small {
    font-size: 4rem;
  }
}
.heading-secondary {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.63;
  letter-spacing: 0.48px;
  color: $color-secondary;
  text-align: center;
  // text-transform: capitalize;
  margin-bottom: 3rem;
  &.left {
    text-align: left;
  }
  .color {
    color: $color-primary;
  }
}
