.map-holder{
    position: relative;
    height: 50vh;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .autocomplete{
        width: 500px;
    }
}