@import "../../styles/utilities";

.header_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 12.4rem;
  z-index: 999;
  background-color: $color-white-1;
  @media only screen and (max-width: 992px) {
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }
  .header_holder {
    height: 12.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8rem;
    @media only screen and (max-width: 992px) {
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .brand {
      margin-right: auto;

      &:lang(ar) {
        margin-right: unset;
        margin-left: auto;
      }
      .brand_logo {
        max-width: 23rem;
        min-width: 18rem;
        img {
          display: block;
          max-width: 100%;
          height: auto;
        }
      }
    }

    .nav {
      margin-right: auto;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5rem;
        li {
          a,
          span {
            font-size: 2rem;
            letter-spacing: 0.14px;
            white-space: nowrap;
            text-transform: capitalize;
            color: $color-black-1;
            transition: 0.2s linear;
            cursor: pointer;
            &:hover {
              color: $color-primary;
              text-shadow: 0px 3px 6px #00000029;
            }
          }

          .link_with_arrow {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 2rem;
            svg {
              font-size: 3rem;
            }
          }
        }
      }
      &.active {
        width: 100%;
      }
    }

    .user_actions {
      display: flex;
      align-items: center;
      gap: 3rem;
      .user_actions_btn {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        padding: 0;
        .user_actions_logo {
          width: 3rem;
          img {
            // object-fit: contain;
          }
          // @media only screen and (max-width: 992px) {
          //   display: none;
          // }
        }
        .label_text {
          font-size: 1.7rem;
          letter-spacing: 0.14px;
          color: $color-black-2;
          white-space: nowrap;
        }
      }
      .label_text {
        font-size: 1.7rem;
        letter-spacing: 0.14px;
        color: $color-black-2;
        white-space: nowrap;
        margin-left: 10px;
        &:lang(ar) {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }

    .btn_menu_toggle {
      display: none;
      padding: 0;
      position: relative;
      z-index: 10;

      svg {
        font-size: 3.2rem;
        color: $color-gray-1;
      }
    }

    @include mq("xl") {
      .nav ul {
        gap: 3.5rem;
      }
    }

    @include mq("lg") {
      gap: 4rem;
      .nav {
        overflow-y: scroll;
        position: fixed;
        top: 0;
        background: $color-primary;
        left: 0;
        bottom: 0;
        // height: 100vh;
        width: 0;
        overflow: hidden;
        max-width: 35rem;
        z-index: 9;
        transition: width 0.5s linear;

        ul {
          display: block;
          li {
            &:first-child {
              margin-top: 7rem;
            }

            a,
            p {
              display: block;
              padding: 1.5rem 4rem;
              text-decoration: none;
              color: $color-white-1;
              text-transform: uppercase;
              font-weight: bold;

              &:hover {
                color: rgba($color-white-1, 0.7);
                text-shadow: unset;
              }
            }
          }
        }

        &:lang(ar) {
          left: unset;
          right: 0;
        }
      }

      .btn_menu_toggle {
        display: inline-block !important;
      }
    }

    @include mq("md") {
      .user_actions .label_text {
        display: none;
      }
    }
  }
}

.user_drop_menu {
  li {
    gap: 1.2rem;
    line-height: 1.6;

    div {
      width: 1.8rem;
      img {
        margin: 0 !important;
      }
    }
    span {
      font-size: 2rem;
      font-weight: normal;
      letter-spacing: 0.13px;
      color: #294151;
      text-transform: capitalize;
    }
  }
}
