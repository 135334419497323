@import "../utilities";

.home_page {
  min-height: 200vh;
}

.side_by_side {
  padding: 8rem 0;
  margin-bottom: 10rem;
  min-height: 80vh;
  @media (min-width: 576px) {
    .container {
      padding: 0 20px;
    }
  }

  @media (min-width: 768px) {
    .container {
      padding: 0 30px;
    }
  }

  @media (min-width: 992px) {
    .container {
      padding: 0 40px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      padding: 0 50px;
    }
  }
  .container {
    width: 100%;
    max-width: 1200px; 
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
  .side_by_side_holder {
    .side_by_side_image {
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
    .side_by_side_text {
      h2 {
        font-size: 6rem;
        font-weight: 600;
        line-height: 1.63;
        letter-spacing: 0.48px;
        color: $color-secondary;
        text-transform: capitalize;
        span {
          display: inline-block;
        }
      }
      p {
        font-size: 2.4rem;
        line-height: 1.68;
        color: $color-gray-3;
        padding-bottom: 4rem;
      }

      button {
        border-radius: 10rem;
        height: 8.8rem;
        width: 24rem;

        span {
          font-size: 2rem;
          font-weight: 500;
          line-height: normal;
          color: $color-white-1;
          text-transform: capitalize;
        }
      }
    }
  }
}
}

.features_cards {
  padding: 8rem 0;
  margin-bottom: 10rem;
  .features_cards_holder {
    .features_cards_title {
      font-size: 6rem;
      font-weight: 600;
      letter-spacing: 0.48px;
      line-height: 1.63;
      color: $color-secondary;
      // text-align: center;
      margin-bottom: 3rem;
      display: flex;
      justify-content: center;
      gap: 13px;
      text-transform: capitalize;

      &:lang(ar) {
        // direction: ltr;  
        :first-child {
          order: 2;
        }
      }
    }
    .features_cards_content {
      .features_cards_card {
        border-radius: 2.6rem;
        box-shadow: 2.8rem 4.9rem 5.9rem 0 rgba(113, 113, 113, 0.1);
        background-color: $color-white-1;
        padding: 2rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // gap: 2rem;
        .card_img {
          padding: 4rem 0;
          min-height: 220px;
          img {
            display: block;
            max-width: 100%;
            height: auto;
          }
        }
        .card_text {
          display: flex;
          flex-direction: column;
          align-items: center;
          //   gap: 2rem;
          h4 {
            font-size: 3.4rem;
            font-weight: 600;
            letter-spacing: 0.27px;
            line-height: 2.88;
            color: $color-secondary;
          }
          p {
            font-size: 2rem;
            line-height: 2.53;
            color: $color-secondary;
            text-align: center;
          }
        }
      }
    }
  }
}

.service_card {
  // padding: 7rem 2rem !important;
}

.slider_design_one {
  padding: 8rem 0;
  margin-bottom: 10rem;
  .slider_design_one_holder {
    .slider_design_one_title {
      font-size: 6rem;
      font-weight: 600;
      letter-spacing: 0.48px;
      line-height: 1.63;
      color: $color-secondary;
      text-align: center;
      margin-bottom: 3rem;
    }
    .slider_design_one_content {
      padding: 8rem 0;
    }
  }
  .card {
    position: relative;
    border-radius: 2.6rem;
    box-shadow: 2.8rem 4.9rem 5.9rem 0 rgba(113, 113, 113, 0.1);
    background-color: $color-white-1;
    padding: 5rem 4rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    .card_header {
      position: relative;
      z-index: 9;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 3rem;

      .user_img {
        width: 12rem;
        background-color: $color-white-1;
        border-radius: 50%;
        box-shadow: 28px 49px 59px 0 rgba(113, 113, 113, 0.1);
        .image {
        }
      }
      .user_rate {
      }
    }
    .card_body {
      position: relative;
      z-index: 9;

      h2 {
        font-size: 2.6rem;
        font-weight: 600;
        line-height: 1.46;
        color: $color-gray-2;
        margin-bottom: 4rem;
      }
      p {
        font-size: 2.4rem;
        line-height: 1.68;
        color: $color-gray-2;
      }
    }

    .bg_blur {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      -webkit-filter: blur(5px);
      filter: blur(5px);
      background-color: $color-white-1;
      z-index: 1;
    }
  }
}
