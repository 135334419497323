@import "../../styles/utilities";

.menu {
  background-color: #fff;
  //   border-top: 3px solid $color-primary;
  box-shadow: 0 -1px 5px $color-primary;
  height: 8rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;

  display: none;

  li {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    min-width: 12rem;
    gap: 0.6rem;
    // background-color: red;

    div {
      width: 2.5rem;
      line-height: normal;
    }
    span {
      font-size: 1.8rem;
      font-weight: 600;
      letter-spacing: 0.13px;
      color: #294151;
      text-transform: capitalize;
      line-height: normal;
      margin: 0;
    }

    &::after {
      display: none;
    }
    .ant-menu-item-selected{
      color: #5e1e63 !important;
    }
    &.ant-menu-item-active,
    &.ant-menu-item-selected{
      a{
        color: #5e1e63;
        font-weight: bold;
      }
    }
  }

  @include mq("sm") {
    display: flex;
  }
}
