@import "../../styles/utilities";

.banner-section-wrapper {
  min-height: 130vh;
  @media only screen and (max-width: 992px) {
    min-height: initial;
    padding-top: 50px;
  }
  .banner-text-content {
    align-items: center;
    justify-content: space-between;
  }
  .background-image-wraper {
    position: absolute;
    width: 100%;
    height: 120%;
    top: 0;
    left: 0;
    background: url("../../public/images/slider-bg-1.jpg");
    background-size: cover !important;
    background-position: 50% 50% !important;
    z-index: 0;
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
  }
  .hero-bottom-shape-two {
    background: url("../../public/images/wave-shap.svg") no-repeat bottom center;
  }
  .hero-bottom-shape,
  .hero-bottom-shape-two {
    position: absolute;
    top: 22%;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  /* line 213, src/assets/scss/objects/_objects.background-image.scss */
  .background-shape-img:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    background-image: url("../../public/images/about-bg-shape.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .banner-text-content {
    h1 {
      font-size: 8rem;
      font-weight: bold;
      letter-spacing: 0;
      color: #fff;
    }
    p {
      font-size: 2.9rem;
      font-weight: medium;
      letter-spacing: 0;
      line-height: normal;
      color: #fff;
    }
    @media only screen and (max-width: 992px) {
      .ant-col {
        padding: 0;
      }
      h1 {
        font-size: 30px;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
      }
      .img-wrap {
        display: none;
      }
    }
    .download-text {
    }
    .action-btns {
      margin-top: 20px;
      .list-inline {
        display: flex;
        align-items: center;
        gap: 10px;

        @media only screen and (max-width: 992px) {
          flex-wrap: wrap;
        }
        .list-inline-item {
          a {
            width: 180px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 7px;
            padding-left: 10px;
            padding-right: 10px;
            background-color: #612166;
            border-color: #612166;
            color: #fff;
            box-shadow: 0px 0px 15px 0 rgb(255 255 255 / 8%);
            border-radius: 22px;
            h5 {
              font-size: 16px;
              color: #fff;
            }
            svg {
              font-size: 25px;
            }
          }
        }
      }
    }
    @include mq("lg") {
      .floating_box {
        gap: 2.4rem;

        h1 {
          font-size: 7rem;
        }
        p {
          font-size: 2.4rem;
        }

        .form_holder {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
