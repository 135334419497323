.footer_hero {
  margin-top: 120px;
  .container {
    .footer_info {
      position: relative;
      top: -75px;
      background: #612166;
      padding: 8rem;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
      border-radius: 0.25rem;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: 767px) {
        text-align: center;
        justify-content: center;
        .footer_content {
          margin-bottom: 5px;
        }
      }
      .footer_content {
        h3 {
          color: #fff;
          margin-bottom: 0;
          font-size: 4rem;
          font-weight: 700;
          line-height: 1.25;
        }
        p {
          color: #fff;
          width: 100%;
          font-size: 15px;
          margin-bottom: 0;
          opacity: 0.8;
        }
      }
      .footer_form {
        position: relative;
        input {
          padding-right: 25px;
          height: 50px;
          display: block;
          width: 100%;
          border: none;
          border-radius: 30px;
          font-size: 14px;
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 100px;
          font-weight: 400;
          line-height: 1.5;
          color: #495057;
          background-color: #fff;
          background-clip: padding-box;
        }
        .form_btn {
          left: 5px;
          right: auto;
          position: absolute;
          top: 4px;
          bottom: 27px;
          height: 40px;
          border: none;
          border-radius: 30px;
          background-color: #612166;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          width: 70px;
          svg {
            font-size: 25px;
          }
        }
      }
    }
  }
}
