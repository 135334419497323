.form-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #0000000d;
  border-radius: 26px;
  padding: 8rem 11rem;
  @media only screen and (max-width: 992px) {
    padding: 6rem 3rem;
  }
  .form-title {
    font-size: 4rem;
    color: #294151;
    margin-bottom: 3.5rem;
    font-weight: 600;
    letter-spacing: 0.32px;
    display: flex;
    align-items: center;
    span {
      color: #612166;
      margin: 0 8px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 3rem;
    }
  }
  .form-txt-p {
    font-size: 2.4rem;
    line-height: 1.58;
    color: #7f8897;
    margin-bottom: 4rem;
    a,
    span {
      font-weight: 500;
      color: #612166;
      margin: 0 6px;
      cursor: pointer;
    }
  }
  .remember-row-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    .ant-checkbox + span {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.16px;
      > a {
        color: #612166;
        font-weight: 600;
        margin: 0 4px;
        cursor: pointer;
      }
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    > a {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.16px;
      color: #294151;
    }
  }
  .have-account-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.16px;
    color: #294151;
    font-size: 20px;
    margin-top: 30px;
    a,
    span {
      color: #612166;
      font-weight: 600;
      margin: 0 4px;
      cursor: pointer;
    }
  }
}

.input-holder {
  margin-bottom: 3rem;
  .ant-input-affix-wrapper,
  .PhoneInput {
    margin-bottom: 0.5rem;
    height: 7.5rem;
    border: 0.5px solid #e6e7ea;
    border-radius: 12px;
    .ant-input-prefix {
      margin: 1rem 0;
      margin-right: 2rem;
      margin-left: 1rem;
      font-size: 2rem;
      img {
        object-fit: contain;
      }
    }
    input {
      letter-spacing: 0.14px;
      // color: #000;
      color: #949ba7;
      font-size: 16px;
      // font-weight: 500;
    }
    .PhoneInput input {
      height: 100%;
      padding: 0;
    }
  }
  textarea {
    letter-spacing: 0.14px;
    // color: #000;
    color: #949ba7;
    font-size: 2.6rem;
    // font-weight: 500;
    border: 0.5px solid #e6e7ea;
    border-radius: 12px;
  }
}

.ant-btn-primary {
  background: transparent linear-gradient(101deg, #8c4191 0%, #612166 100%) 0%
    0% no-repeat padding-box !important;
  color: #ffffff;
  border: 0;
  &.ant-btn-lg {
    height: 78px;
    font-size: 25px;
    border-radius: 39px;
    @media only screen and (max-width: 992px) {
      height: 58px;
      font-size: 18px;
      border-radius: 26px;
    }
  }
}

.otp-form-holder {
  > div {
    margin-bottom: 3.5rem;
    //   flex-direction: row-reverse;
    .input-otp {
      // width: 82px;
      // @include mq("tablet"){
      //   width: 50px;
      // }
    }
  }
}
.input-otp {
  input {
    width: 8.2rem !important;
    height: 7.8rem;
    font-size: 3rem;
    border-radius: 12px;
    border: 1px solid #93959880;
    &.active-input {
      background-color: #65246a;
      color: #fff;
    }
    //   @include mq("tablet"){
    //     width: 44px !important;
    //     height: 44px;
    //   }
  }
  span {
    margin: 0 1rem;
  }
}

.ant-radio-group {
  .ant-radio-wrapper {
    .ant-radio-inner {
      width: 4.3rem !important;
      height: 4.3rem !important;
      border-color: $color-primary;

      &::after {
        width: 2.1rem !important;
        height: 2.1rem !important;
        transform: translate(0);
        background-color: $color-primary;
      }
    }
  }
}

.input-wrapper {
  .ant-input-number{
    .ant-input-number-input-wrap{
      height: 100%;
    }
    input{
      height: 100%;
    }
  }
  .ant-input, .ant-select .ant-select-selector, .ant-input-number, .pac-target-input {
    margin-bottom: 0.5rem;
    height: 7.5rem;
    border: 0.5px solid #e6e7ea;
    border-radius: 12px;
    letter-spacing: 0.14px;
    color: $color-black-2;
    font-size: 2.1rem;
    padding: 4px 11px;
    width: 100%;
  }
  .ant-select .ant-select-selector{
    input{
      height: 100% !important;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
  .ant-select-single .ant-select-selector .ant-select-selection-item{
    display: flex;
    align-items: center;
  }
  textarea {
    letter-spacing: 0.14px;
    // color: #000;
    color: #949ba7;
    font-size: 1.6rem;
    height: auto !important;
    border: 0.5px solid #e6e7ea;
    border-radius: 12px;
    padding: 2rem;
  }
}
.input-address-wrapper{
  margin-bottom: 30px;
}
.input-addon-wrapper{
  position: relative;
  // .phone-with-addon{
    .change-txt{
      position: absolute;
      top: 14px;
      right: 14px;
      right: 0;
      color: #612166;
      text-transform: capitalize;
      cursor: pointer;
      width: fit-content;
      &:lang(ar){
        left: 14px;
        right: auto;
      }
    }
    // }
}

.phone-with-addon{
  @media only screen and (max-width: 992px) {
    ~ button{
      display: none;
    }
  }
}