@import "../../styles/utilities";

.modal_wrapper {
  .close_icon {
    position: relative;
    top: 1rem;
    right: 1rem;
    font-size: 3rem;
  }
}
.own_modal {
  background-color: transparent;

  > div {
    background-color: transparent;
    box-shadow: none;
  }
}
