@import "../../styles/utilities";

.footer_wrapper {
  position: relative;

  @include mq("sm") {
    padding-bottom: 8rem;
  }
}

.footer_cover {
  background-color: $color-primary;
  background-image: url("../../public/images/pattern-4.png");
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;

  .cover_holder {
    height: 42vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;

    .cover_text {
      //   width: 50%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 3.2rem;

      h2 {
        font-size: 8rem;
        font-weight: bold;
        letter-spacing: 0;
        color: $color-white-1;
        white-space: nowrap;
      }
      p {
        font-size: 2.7rem;
        font-weight: normal;
        letter-spacing: 0;
        line-height: normal;
        color: $color-white-1;
      }
      .apps_links {
        display: flex;
        align-items: center;
        gap: 1rem;
        a {
          width: 23.5rem;
        }
      }
    }

    .cover_image {
      flex: 1;
      align-self: flex-end;
    }

    @include mq("lg") {
      height: 50vw;

      .cover_text {
        gap: 3rem;

        h2 {
          font-size: 7rem;
        }
        p {
          font-size: 2.5rem;
        }
        .apps_links {
          a {
            width: 22rem;
          }
        }
      }
    }
    @include mq("md") {
      .cover_text {
        gap: 3rem;
        max-width: 90%;

        h2 {
          font-size: 6rem;
        }
        p {
          font-size: 2.5rem;
        }
        .apps_links {
          a {
            width: 20rem;
          }
        }
      }
      .cover_image {
        display: none;
      }
    }
    @include mq("sm") {
      height: 70vw;

      .cover_text {
        gap: 2rem;
        max-width: 90%;

        h2 {
          font-size: 5rem;
        }
        p {
          font-size: 2rem;
        }
        .apps_links {
          a {
            width: 18rem;
          }
        }
      }
    }
  }
}

.footer_content {
  background-image: url("../../public/images/Mask Group 76.png");
  background-position: 50% bottom;
  background-size: contain;
  background-repeat: no-repeat;
  @include mq("sm") {
    padding-bottom: 90px;
  }
  .footer_content_holder {
    margin-top: 6rem;
  }
}

.brand {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  .brand_logo {
    margin-left: -2rem;
  }
  .brand_text {
    p {
      font-size: 1.8rem;
      letter-spacing: 0;
      color: $color-gray-2;
      line-height: 3.8rem;
    }
    .address {
      display: flex;
      gap: 0.8rem;
      margin-top: 1.8rem;

      img {
        // width: 4rem !important;
      }

      p {
        font-size: 1.8rem;
        letter-spacing: 0.14px;
        color: $color-gray-1;
      }
    }
  }
  .social {
    h5 {
      font-size: 2.1rem;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.17px;
      text-transform: capitalize;
      color: $color-black-1;
      margin-bottom: 3rem;
    }
    ul {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      li {
        a {
          img {
            height:5rem;
            width: 5rem;
          }
        }
      }
    }
  }
}

.footer_list_holder {
  position: relative;
  z-index: 9;

  .footer_list_title {
    font-size: 2.1rem;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.17px;
    color: $color-black-1;
    text-transform: capitalize;
    border-bottom: 3px solid $color-primary;
    position: relative;
    display: inline-block;
    margin-bottom: 5rem;
    padding: 1rem 0;

    &::after {
      content: "";
      height: 3px;
      width: 0.5rem;
      background-color: $color-white-1;
      position: absolute;
      bottom: -3px;
      right: 1.2rem;
    }
  }

  ul {
    li {
      a {
        font-size: 2.1rem;
        font-weight: 500;
        line-height: 3.67;
        letter-spacing: 0.17px;
        white-space: nowrap;
        color: $color-gray-1;
        text-transform: capitalize;
      }
    }
  }
}

.footer_copyright {
  position: relative;
  z-index: 9;
  padding: 6rem 0 3rem 0;
  p {
    font-size: 2rem;
    line-height: 1.9;
    color: $color-gray-2;
    @include mq("sm") {
      width: 75%;
      text-align: center;
      margin: auto;
    }
    a {
      color: $color-primary-light;
    }
  }
}

.side_image {
  // background-image: url("../../public/images/Group 25856.png");
  background-image: url("../../public/images/banner 1-01.png");
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 40rem;
  height: 40rem;

  position: absolute;
  right: 0;
  bottom: -3rem;
  z-index: 1;

  &:lang(ar) {
    right: auto;
    left: 0;
    transform: rotateY(180deg);
  }
}
