@import url("https://fonts.googleapis.com/css2?family=El+Messiri&family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import "./utilities";
@import "./reset";
@import "./custom_antd";
@import "./typography";
@import "./form";
@import "./map";
@import "./components/sliders.scss";

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../public/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../public/fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../public/fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../public/fonts/Poppins-Bold.ttf) format("truetype");
}

html {
  // font-size: 62.5%;
  font-size: 45%;
  @include mq("xxl") {
    // font-size: 50%;
    font-size: 45%;
  }

  @include mq("lg") {
    font-size: 45%;
  }
  @include mq("sm") {
    font-size: 40%;
  }
}

body {
  font-family: $font-primary;
  font-size: 100%;
  line-height: 1.6;
  scroll-behavior: smooth;
  zoom: 0.93;
}

.app-content {
  padding-top: 12.4rem;
  min-height: 70vh;
  .ant-tooltip-placement-top {
    display: none !important;
  }
  @include mq("lg") {
    padding-top: 5.4rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.ant-breadcrumb {
  color: #ffff;
  background-color: transparent;
  .ant-breadcrumb-link {
    color: #ffff;
  }
  .ant-breadcrumb-separator {
    color: #ffff;
  }
}

.gmap_canvas {
  a {
    display: none !important;
  }
}

.avatar-holder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 80px;
  .ant-avatar > img {
    // object-fit: contain;
  }
  .ant-avatar-image {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
    border: solid 1px #fff;
    height: 142px !important;
    width: 142px !important;
  }
  .ant-upload.ant-upload-select {
    height: 52px;
    width: 52px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -10px;
    right: -10px;
    cursor: pointer;
    .ant-upload {
      line-height: 16px;
    }
    svg {
      margin: 0;
      font-size: 16px;
      color: #fff;
    }
  }
}

.PhoneInputCountry {
  background: #fff;
  padding-left: 16px;
  padding-right: 8px;
  margin-left: 6px;
  &:lang(ar) {
    padding-right: 16px;
    padding-left: 8px;
  }
}

.PhoneInputInput {
  border: 0;
  padding: 0;
  height: 100%;
  &:lang(ar) {
    direction: ltr;
    text-align: end;
  }
}

.PhoneInputCountrySelectArrow {
  color: #000;
  opacity: 1;
  font-size: 21px;
  &:lang(ar) {
    margin-left: 0;
    margin-right: 6px;
  }
}

.ant-collapse-borderless > .ant-collapse-item {
  &.ant-collapse-item-active {
    h4 {
      background-color: $color-primary;
      color: $color-white-1 !important;
      transition: all ease-in-out 0.3s;
    }
    p {
      // background-color: $color-white-1;
    }
  }
}

.my_order_page {
  .ant-tabs {
    .ant-tabs-nav-wrap {
      // justify-content: center;
      align-items: center;
      padding: 2rem;
      // overflow-x: auto;
      justify-content: flex-start;
      &::-webkit-scrollbar {
        height: 7px;
        // display: none;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        // display: none;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #612166;
        // width: 6px;
        border-radius: 4px;
        // display: none;
      }
      .ant-tabs-nav-list {
        gap: 2rem;
        justify-content: center;
        // flex-wrap: wrap;
      }
      .ant-tabs-tab {
        min-width: 25.3rem;
        min-width: 20rem;
        height: 8.1rem;
        border-radius: 15px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
        background-color: #2e2e2e;
        border-radius: 1rem;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 16px;
        padding-right: 16px;
        .ant-tabs-tab-btn {
          font-size: 2.7rem;
          font-weight: 400;
          text-transform: capitalize;
          color: #fff;
        }

        &.ant-tabs-tab-active {
          background-color: $color-primary;
          .ant-tabs-tab-btn {
            color: #fff;
          }
        }
      }
      @include mq("md") {
        padding: 2rem 1rem;

        .ant-tabs-tab {
          // min-width: 23rem;
          height: 7.8rem;

          .ant-tabs-tab-btn {
            font-size: 2.2rem;
          }
        }
      }
    }
    //
    .ant-tabs-ink-bar-animated,
    .ant-tabs-nav::before {
      display: none !important;
    }
  }
}

.select_card_holder {
  .ant-radio-button-wrapper {
    &.ant-radio-button-wrapper-checked {
      border-color: #612166 !important;
      box-shadow: 0 3px 99px 0 rgba(0, 0, 0, 0.04);
    }
  }
  img {
    object-fit: contain;
  }
}

strong {
  font-weight: 600;
}

.company-active {
  border: 1px solid #612166 !important;
  box-shadow: 0 3px 99px 0 rgb(0 0 0 / 4%);
}

.ant-image-img {
  object-fit: contain;
}

.order-delivery-page-holder {
  .ant-form-item-explain-error {
    display: none !important;
  }
}

.shared-title {
  font-size: 4rem;
  color: #294151;
  margin-bottom: 3.5rem;
  font-weight: 600;
  letter-spacing: 0.32px;
  span {
    color: #612166;
  }
}

.avatar-card {
  border-radius: 26px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 5%);
  padding: 20px 30px;
  margin-bottom: 25px;
  border: 1px solid #f5f5f5;
}

.is-seen {
  background-color: #f0f0f0 !important;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #d9d9d9;
}

.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: rgba(0, 0, 0, 0.85);
}

.ant-image-preview-wrap {
  z-index: 1080555555;
}

.send-sms-phone {
  padding: 20px 0;
  .input-holder,
  .input-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .ant-form-horizontal .ant-form-item-control {
    width: 100%;
  }
  .ant-form-item-label > label {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .ant-form-item-control {
    width: 100%;
  }
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-tabs-nav-operations {
  .anticon svg {
    font-size: 25px;
  }
}

.ant-tooltip-hidden {
  display: none !important;
}

.upload_holder {
  .ant-tooltip,
  .ant-tooltip-content {
    display: none !important;
  }
}

.comming-soon {
  font-size: 12px;
  color: #612166;
}

.track-info-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  time {
  }
}

.box-btns {
  position: sticky;
  bottom: 0;
  z-index: 2;
  padding-bottom: 16px;
  background-color: #fff;
}

.ant-picker-ok {
  button {
    &:disabled {
      color: rgba(0, 0, 0, 0.25) !important;
      border-color: #d9d9d9 !important;
      background: #f5f5f5 !important;
    }
  }
}

.lang-ltr {
  &:lang(ar) {
    direction: ltr;
    justify-content: flex-start !important;
  }
}

.about-page-holder {
  .page_hero_wrapper {
    background-image: url("../public/images/about-chauffeur.png") !important;
    background-position: center;
  }
}

.contact-page-holder {
  .page_hero_wrapper {
    background-image: url("../public/images/Contact-Us.png") !important;
    // background-position: center;
  }
}

.shipping_page_holder {
  .ant-skeleton {
    box-shadow: 0 0 99px 0 rgb(0 0 0 / 4%);
    background-color: #fff;
    border-radius: 13px;
    margin-bottom: 3.2rem;
    padding: 3rem;
  }
}

.chat-window-wrapper {
  position: relative;
  .ant-upload-list-picture {
    position: absolute;
    bottom: 60px;
    left: 50px;
    right: 90px;
    background-color: #fff;
    padding: 0;
    width: max-content;
    .ant-upload-list-item {
      margin-top: 0;
    }
  }
  .time-holder {
    position: absolute;
    bottom: 4px;
    right: 40px;
    font-size: 11px;
    color: gray;
  }
}
.res-menu-bar {
  li {
    &.ant-menu-item-active,
    &.ant-menu-item-selected {
      a {
        color: #5e1e63 !important;
        font-weight: bold;
      }
    }
  }
}
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
//   color: #612166;
// }

.res-hidden {
  @media only screen and (max-width: 992px) {
    display: none !important;
  }
}

.event-none {
  pointer-events: none;
}

.soon-p {
  font-size: 16px;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 16px;
  background: transparent linear-gradient(101deg, #8c4191 0%, #612166 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 28px;
  font-style: italic;
  min-width: 100px;
}

.ant-checkbox-checked::after {
}

.ant-modal-wrap-rtl .ant-modal-close {
  left: 20px;
}

.wats-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  // color: rgb(37, 211, 102);
  // font-size: 40px;
  // background-color: #f0f0f0;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 20;
  @media only screen and (max-width: 992px) {
    right: 10px;
    bottom: 70px;
    &:lang(ar) {
      right: auto;
      left: 10px;
    }
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 28px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .message-container {
    background: rgb(37, 211, 102);
    color: #fff;
    padding: 10px 18px;
    border-radius: 16px;
    width: auto;
    display: inline-block;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.2) !important;
    display: flex;
    align-items: center;
    gap: 10px;
    @media only screen and (max-width: 992px) {
      display: none;
    }
    .online {
      width: 10px;
      height: 10px;
      // display: inline-block;
      background: #fff;
      border-radius: 20px;
    }
  }
  &:lang(ar) {
    right: auto;
    left: 30px;
  }
  &:hover {
    color: #128c7e;
  }
}
.radio-group-holder {
  .ant-radio-wrapper {
    display: inline-flex;
    align-items: center;
    .ant-radio {
      top: 0;
    }
    .ant-radio-inner {
      // width: 30px !important;
      // height: 30px !important;
      transform: scale(0.7);
    }
    span.ant-radio + * {
      font-size: 16px;
    }
  }
}
.contact-icon {
  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: 1px solid #612166;
  border-radius: 50%;
}
.contact-info-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  li {
    display: flex;
    // align-items: center;
    gap: 16px;

    .contact-text {
      display: flex;
      flex-direction: column;
      gap: 5px;
      h5 {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25;
        color: #1b2e4b;
      }
      p {
        // font-size: 16px;
        color: #000;
      }
    }
  }
}

.vedio-modal-wrapper {
  width: 90vw !important;
  height: 70vh;
  .ant-modal-close {
    color: red;
    svg {
      font-size: 25px;
    }
  }
  .ant-modal-content {
    width: 100%;
    height: 100%;
    .ant-modal-body {
      width: 100%;
      height: 100%;
      padding: 0;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.spin-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.9);
}

.ant-btn-dangerous.ant-btn-primary{
  background: #ff4d4f !important;
}
.action-btn-setting{
  display: flex;
  align-items: center;
  gap: 12px;
  button{
    flex: 1;
  }
}
.form-wrapper{
  .profile-header-holder{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3.5rem;
    h3{
      margin-bottom: 0;
    }
    .ant-btn-dangerous.ant-btn{
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.32px;
    }
  }
}

.not-found{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  gap: 20px;
  h1{
    font-size: 36px;
    font-weight: 600;
    line-height: 1.25;
    color: #1b2e4b;
  }
  p{
    font-size: 20px;
    color: #000;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 0;
    max-width: 400px;
    width: 100%;
    @media only screen and (max-width: 992px) {
      max-width: 100%;

    }
  }
  ul{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 0;
    li{
      font-size: 20px;
      color: #000;
      text-align: center;
    }
  }
  img{
    width: 70%;
    margin: auto;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }
}