.ant-dropdown {
  box-shadow: $shadow-1;
  ul {
    padding: 2rem 0;
    width: 24rem;
    border-radius: 1rem;
    li {
      padding: 1rem 2rem;
      background-color: unset !important;

      &:hover {
        a {
          color: $color-primary-light;
        }
      }
      a {
        font-size: 2rem;
        font-weight: 500;
        text-transform: capitalize;
        color: $color-primary;
      }
    }
  }
  @include mq("lg") {
    box-shadow: none;
    // left: 12rem !important;
    // top: 23rem !important;
  }
}

.ant-scroll-number {
  background-color: $color-primary-light !important;
  span {
    font-size: 1.6rem !important;
    color: $color-white-1 !important;
  }
}

.breadcrumb {
  font-size: 2rem;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.16px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  .breadcrumb-to {
    a {
      color: $color-white-1;
      white-space: nowrap;
    }
  }
  .breadcrumb-current {
    color: $color-white-1;
    white-space: nowrap;
  }
  .ant-breadcrumb-separator {
    color: $color-white-1 !important;
  }
}

////////////
.ant-pagination {
  text-align: center;
  .ant-pagination-item {
    border: none;
    min-width: 2rem;
    a {
      font-family: $font-primary;
      font-size: 1.8rem;
      color: #bfbfbf;
    }
  }

  .ant-pagination-item-active {
    a {
      color: $color-primary;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border-radius: 1rem;
      border-color: $color-gray-2;

      span {
        svg {
          color: $color-gray-2;
        }
      }
    }
  }

  .ant-pagination-disabled {
  }
}

.banner_hero {
  .ant-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .ant-form-item {
      // width: 100%;
      // max-width: 25rem;
      // margin: 0;

      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input-affix-wrapper {
              border-radius: 8px;
              box-shadow: 0px 3px 99px #00000014;
              overflow: hidden;
              width: 55rem !important;
              height: 6.9rem;

              background-color: $color-white-1;
              .ant-input-prefix {
                // width: 2rem !important;
              }

              @include mq("md") {
                width: 50rem !important;
                height: 6.9rem;
              }
              @include mq("sm") {
                width: 37rem !important;
                height: 6rem;
              }
            }
            input {
              width: 100%;
              border: none;
              background-color: $color-white-1;
              color: $color-black-1;
              font-size: 1.7rem;
              padding: 1rem 2rem;
              // border-radius: 0.5rem;

              &::placeholder {
                color: $color-gray-2;
                font-size: 1.5rem;
              }
            }

            .ant-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
              border: 0;
              border-radius: 8px;
              cursor: pointer;
              height: 6.9rem;
              width: 7.2rem;
              background-color: $color-primary !important;
              background-image: url("../public/images/Group\ 25861.png");
              background-size: 40rem;
              background-position: 50% 49%;

              @include mq("sm") {
                width: 6rem;
                height: 6rem;
                background-size: 35rem;
              }
            }
          }
        }
      }
    }
  }
}

// modal
// .ant-modal-mask {
//   opacity: 0.83;
//   background-color: #000;
// }

.search-hero {
  .ant-input-group-addon {
    background-color: transparent;
  }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: #fff;
  opacity: 0.8;
}

.ant-picker-active-bar {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.ant-picker-range-separator {
  svg {
    color: rgba(255, 255, 255, 0.7) !important;
  }
}

.site-calendar-customize-header-wrapper {
  // border-radius: 1rem 1rem 0 0;
  // padding: 2rem;
  // width: 100%;
  width: 45rem;
  margin: auto;
  // background-color: $color-white;
  padding: 1.7rem;
  background-color: #fcfcff;
  border-radius: 23px;

  .ant-picker-calendar-mini {
    .ant-select-show-arrow {
      .ant-select-selector {
        border-radius: 1rem;
        background-color: #f8f7fa;
        border: 0;
        height: auto;
        padding: 1rem 1.8rem;
        .ant-select-selection-search {
          input {
          }
        }
        .ant-select-selection-item {
          color: $color-primary;
        }
      }

      .ant-select-arrow {
        .ant-select-suffix {
        }
      }
    }

    .ant-picker-panel {
      border: 0;
      table.ant-picker-content {
        th {
          font-size: 1.3rem;
          font-weight: 600;
          color: #000;
          padding-bottom: 1rem;
        }

        td {
          &.ant-picker-cell-in-view {
            .ant-picker-calendar-date-value {
              color: #000 !important;
            }
          }
          .ant-picker-calendar-date {
            width: 4rem;
            height: 4rem;
            .ant-picker-calendar-date-value {
              font-size: 1.6rem;
              color: #000 !important;
              line-height: 4rem;
            }
          }

          .ant-picker-calendar-date-today {
            &::before {
              border: 1px solid #888;
              border-radius: 50%;
            }
          }

          &.ant-picker-cell-selected {
            .ant-picker-calendar-date {
              background-color: $color-primary;
              border-radius: 50%;

              .ant-picker-calendar-date-value {
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}
